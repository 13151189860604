import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { getAppTheme } from 'Theme/theme'
import { appThemeConfigs } from 'config/app-config'
import { AppThemeConfigs } from 'types'
import { useAppDispatch } from 'redux/hooks'
import { setThemeConfig } from 'redux/reducers/globals/globalActions'

function AppThemeProvider({ children }: {
    children: JSX.Element | JSX.Element[] | any
}): JSX.Element {
    const location = useLocation()
    const dispatch = useAppDispatch()

    const selectedAppTheme = useMemo(() => {
        const keys = Object.keys(appThemeConfigs)
        for (let i = 0; i < keys.length; i++) {
            const themeConfigKey = keys[i] as keyof AppThemeConfigs;
            const theme = appThemeConfigs[themeConfigKey]
            if (theme.pathReg && location.pathname.search(theme.pathReg) > -1)
                return theme
        }
    }, [location.pathname])

    useEffect(() => {
        console.log('dispatch(setThemeConfig())... ', selectedAppTheme)
        dispatch(setThemeConfig(selectedAppTheme || appThemeConfigs.default))
    },[selectedAppTheme])

    const selectedTheme = useMemo(() => {
        return getAppTheme(selectedAppTheme?.themeName)
    }, [selectedAppTheme])

    return (
        <ThemeProvider theme={selectedTheme}>{children}</ThemeProvider>
    )
}

export default AppThemeProvider