/* eslint-disable */
// @ts-nocheck TODO need to add types
// save and invester App.js
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import Web3 from 'web3'
import Contract from 'web3-eth-contract'
import constantConfig from './config/constantConfig'
import { BNB_StakingContract, ETH_StakingContract, JNTR_TB_ContractAddress, JNTR_E_ContractAddress } from 'app-constants'
// import stakingAbi from './abi/stakingAbi.json'
import tokenAbi from './abi/tokenAbi.json'
import normalTokenAbi from './abi/normalTokenAbi.json'
import CONSTANT from 'app-constants/reimbursement'
import notificationConfig from './config/notificationConfig'
// import StakingContract from './helper/stakingContract'
import stakingTokenAbi from './abi/stakingTokenAbi.json'
import reimbursementStakingAbi from 'abi/reimbursementStakingAbi.json'
import * as HodlStakingActions from './actions/HodlStakingActions'
import HodlStakingStores from './stores/HodlStakingStores'
import { ethers } from 'ethers'
import { coingeckoAPIURl } from './config/pathConfig'
import axios from 'axios'
import useWallet from 'hooks/useWallet'
import { useAppChain, useWeb3 } from 'hooks'
import { AbiItem, fromWei } from 'web3-utils'
import { ReimbursementSetting, TableObject, VaultsBalance } from 'types'

export const App2Context = createContext({
  balances: null,
  setting: null,
  balance: null,
  stakingTableDatas: null,
  unstakingAmounts: null,
  web3: null,
  sendOptionsData: () => {},
  connectWallet: () => {},
  getSetting: () => {},
})

interface IApp2ContextProviderProps {
  children: JSX.Element
}

function App2ContextProvider({ children }: IApp2ContextProviderProps): JSX.Element {
  // const [web3, setweb3] = useState(null)
  // const [instanceStakingBinance, setinstanceStakingBinance] = useState(null)
  // const [instanceStakingEthereum, setinstanceStakingEthereum] = useState(null)
  const [balances, setbalances] = useState({ balance: 0, eth: 0, bnb: 0, jntrb: 0 })
  const [tokenBalances, settokenBalances] = useState({
    JNTR: 0,
    'JNTR/b': 0,
    'JNTR/e': 0,
    JNTR_APPROVED: 0,
    'JNTR/b_APPROVED': 0,
    'JNTR/e_APPROVED': 0,
  })
  // const [jntrbStakingList, setjntrbStakingList] = useState([])
  // const [jntreStakingList, setjntreStakingList] = useState([])
  // const [jntrStakingList, setjntrStakingList] = useState([])
  // const [popupData, setpopupData] = useState({
  //   token: 'JNTR/b',
  //   stakingAmount: 0.0,
  //   id: 0,
  //   rewardAPY: 0.0,
  //   rewardDays: 1,
  // })
  // const [termStakingList, settermStakingList] = useState([])
  const [stakingOptionsList, setstakingOptionsList] = useState([])
  // const [stakingOptionsUI, setstakingOptionsUI] = useState([])
  // const [stakingInstance, setstakingInstance] = useState(null)
  // const [launchPoolLoading, setlaunchPoolLoading] = useState(false)
  const [options, setoptions] = useState([])
  const [orders, setorders] = useState([])
  // const [stakingPeriod, setstakingPeriod] = useState('')
  // const [stakingPercent, setstakingPercent] = useState('')
  // const [stakingOptionId, setstakingOptionId] = useState('')
  const [balance, setbalance] = useState<VaultsBalance>({
    balance: [],
    vault: [],
  })
  const [setting, setsetting] = useState<ReimbursementSetting[]>([])
  const [unstakingAmounts, setunstakingAmounts] = useState<string[]>([])
  const [stakingTableDatas, setstakingTableDatas] = useState<TableObject[]>([])

  const { library, chainId: connectedChainId, account, active, balance: mainTokenBalance } = useWallet()
  const { appChainId }  = useAppChain()
  const web3 = useWeb3()

  const web3Ethereum = useMemo(() => new Web3(new Web3.providers.WebsocketProvider(CONSTANT.RPC_PROVIDERS[1])), [])
  const web3Binance = useMemo(() => new Web3(new Web3.providers.HttpProvider(CONSTANT.RPC_PROVIDERS[56])), [])

  const tokenInstance = useMemo(() => {
    if (web3Binance.eth?.Contract && web3Ethereum.eth?.Contract) {
      let _tokenInstance = {
        'JNTR/b': new web3Binance.eth.Contract(tokenAbi as AbiItem[], JNTR_TB_ContractAddress),
        'JNTR/e': new web3Ethereum.eth.Contract(tokenAbi as AbiItem[], JNTR_E_ContractAddress)
      }
      // setinstanceStakingBinance(new web3Binance.eth.Contract(stakingAbi, constantConfig[CONSTANT.NETWORK_ID.BINANCE].stakingContract))
      // setinstanceStakingEthereum(new web3Ethereum.eth.Contract(stakingAbi, constantConfig[CONSTANT.NETWORK_ID.ETHEREUM].stakingContract))
      return _tokenInstance
    }
  }, [web3Binance.eth.Contract, web3Ethereum.eth.Contract])

  const fetchedStakingOptions = useCallback(() => {
    setstakingOptionsList(HodlStakingStores.getStakingOptions())
  }, [])

  const createStakingOptionsResponse = useCallback(() => {
    HodlStakingActions.fetchStakingOptions()
    HodlStakingStores.getCreateStakingOptionsResponse()
  }, [])

  const getOptions = useCallback(async () => {
    let provider = CONSTANT.RPC_PROVIDERS[appChainId]
    // @ts-ignore
    Contract.setProvider(provider)
    // @ts-ignore
    var stakingContract = new Contract(stakingTokenAbi, constantConfig[appChainId].stakingContract)
    var _options = await stakingContract.methods.getOptions().call()
    setoptions(_options)
  }, [appChainId])

  const setValues = useCallback((_balances: VaultsBalance, _settings: ReimbursementSetting[], _unstakingAmounts: string[], _stakingTableDatas: TableObject[]) => {
    setbalance(_balances)
    setsetting(_settings)
    setunstakingAmounts(_unstakingAmounts)
    setstakingTableDatas(_stakingTableDatas)
  }, [])

  const getSetting = useCallback(async () => {
    let provider = CONSTANT.RPC_PROVIDERS[appChainId]
    // let testAccount = '0x084374b068eb3db504178b4909edc26d01226a80'
    // @ts-ignore
    Contract.setProvider(provider)
    let settings: ReimbursementSetting[] = []
    // @ts-ignore
    var reimbursement = new Contract(reimbursementStakingAbi, constantConfig[appChainId].reimbursementStakingContract)
    var vaultsBalance: VaultsBalance = {
      balance: [],
      vault: [],
    }
    var unstakingAmounts: string[] = []
    var stakingTableData = []
    vaultsBalance = await reimbursement.methods.getVaultsBalance(account).call()
    for (let i = 0; i < vaultsBalance.vault.length; i++) {
      var setting = await reimbursement.methods.settings(vaultsBalance.vault[i]).call()
      settings.push(setting)
      var unstakingAmount = await reimbursement.methods.getStakedAmount(account, vaultsBalance.vault[0]).call()
      unstakingAmounts.push(unstakingAmount)
    }
    for (let j = 0; j < settings.length; j++) {
      var tokenPrice = 1 // TODO get token price from web3
      var totalBalance = vaultsBalance.balance[j]
      // @ts-ignore
      var tokenContract = new Contract(normalTokenAbi, settings[j].token)
      var tokenName = await tokenContract.methods.name().call()
      var tableObject: TableObject = {
        id: j,
        token : tokenName,
        fee : 0,
        total : +fromWei(totalBalance) / balances.jntrb,
        reimbursement : +fromWei(totalBalance) * tokenPrice,
        ratio : settings[j].reimbursementRatio,
        period : settings[j].period / 3600 / 24,
        staking : 'claim',
        balance : Web3.utils.fromWei(unstakingAmounts[j]),
        withdrawal : 'withdraw',
      }
      stakingTableData.push(tableObject)
    }
    setValues(vaultsBalance, settings, unstakingAmounts, stakingTableData)
  }, [account, appChainId, balances.jntrb, setValues])

  const sendOptionsData = useCallback((_options) => {
    setoptions(_options)
  }, [])

  const getOrders = useCallback((_orders) => {
    setorders(_orders)
  }, [])

  const getBalances = useCallback((balance, eth, bnb, jntrb) => {
    setbalances({ balance: balance * 1, eth: eth, bnb: bnb, jntrb })
  }, [])

  const updateTokenBalance = useCallback(async () => {
    // console.log("updateTokenBalance: ")
    // console.log("web3: ", web3)
    // Balances
    let _tokenBalances = {}
    if (connectedChainId === 1) {
      _tokenBalances['JNTR/e'] = fromWei(await tokenInstance['JNTR/e'].methods.balanceOf(account).call(), 'ether')
      _tokenBalances['JNTR/e_APPROVED'] = Web3.utils.fromWei(await tokenInstance['JNTR/e'].methods.allowance(account, ETH_StakingContract).call(), 'ether')
    } else if (connectedChainId === 56) {
      const balance0 = await tokenInstance['JNTR/b'].methods.balanceOf(account).call({ from: account })

      _tokenBalances['JNTR/b'] = fromWei(balance0, 'ether')
      // Approve Balances
      const balance1 = await tokenInstance['JNTR/b'].methods.allowance(account, BNB_StakingContract).call({ from: account })
      _tokenBalances['JNTR/b_APPROVED'] = Web3.utils.fromWei(balance1, 'ether')
    }
    // console.log("tokenBalances: ", tokenBalances)
    settokenBalances((p) => ({
      ...p,
      ..._tokenBalances,
    }))
  }, [account, tokenInstance, connectedChainId])

  const connectWallet = useCallback(async () => {
    let ethPrice = 0, bnbPrice = 0, jntrnbPrice = 0;
    try {
      let res = await axios.get(`${coingeckoAPIURl}/coins/ethereum`);
      ethPrice = res.data.market_data.current_price.usd;
    } catch (error) {
      console.log({ error })
    }
    try {
      let res = await axios.get(`${coingeckoAPIURl}/coins/binancecoin`)
      bnbPrice = res.data.market_data.current_price.usd
    } catch (error) {
      console.log({ error })
    }
    try {
      let res = await axios.get(`${coingeckoAPIURl}/coins/binance-smart-chain/contract/${CONSTANT.currencyAddresses['JNTR/b']}`)
      jntrnbPrice = res.data.market_data.current_price.usd
    } catch (error) {
      console.log({ error })
    }
    if (!constantConfig.allowedNetwork.includes(connectedChainId)) {
      notificationConfig.error('Connect your wallet again! Allowed chains are ' + constantConfig.allowedNetwork.join(', '))
      return
    }
    let stakingAddress = constantConfig[connectedChainId].stakingContract
    let stakingInstance = new ethers.Contract(stakingAddress, stakingTokenAbi, library.getSigner(0))
    let options = await stakingInstance.getOptions()
    sendOptionsData(options)
    let orders = await stakingInstance.getOrders(account)
    getOrders(orders)
    getBalances(mainTokenBalance, ethPrice, bnbPrice, jntrnbPrice)
    getSetting()
  }, [account, connectedChainId, getBalances, getOrders, getSetting, library, mainTokenBalance, sendOptionsData])

  useEffect(() => {
    // componentWillMount
    HodlStakingActions.fetchStakingOptions()
    HodlStakingStores.on('FETCH_STAKING_OPTIONS', fetchedStakingOptions.bind(this))
    HodlStakingStores.on('CREATE_STAKING_OPTION', createStakingOptionsResponse.bind(this))
    if (!active) {
      getOptions()
    } else {
      connectWallet()
    }

    // componentDidMount

    return () => {
      HodlStakingStores.removeListener('FETCH_STAKING_OPTIONS', fetchedStakingOptions)
      HodlStakingStores.removeListener('CREATE_STAKING_OPTION', createStakingOptionsResponse)
    }
  }, [active, connectWallet, createStakingOptionsResponse, fetchedStakingOptions, getOptions])

  useEffect(() => {
    let timer
    timer = setInterval(() => {
      updateTokenBalance()
    }, 3000)
    return () => {
      clearInterval(timer)
    }
  }, [updateTokenBalance])

  return (
    <>
      <App2Context.Provider
        value={{
          balances: balances,
          setting: setting,
          balance: balance,
          stakingTableDatas: stakingTableDatas,
          unstakingAmounts: unstakingAmounts,
          sendOptionsData: sendOptionsData,
          connectWallet: connectWallet,
          getSetting: getSetting,
          web3: web3,
        }}
      >
        {children}
      </App2Context.Provider>
    </>
  )
}

export default App2ContextProvider
